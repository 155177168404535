<template>
    <div class="container pb-5">
        <div class="bg-title mt-5">
            <div class="text-white text-center p-4" style="font-size: 20px">
                <strong>{{noticia[0].titulo}}</strong><br>
                <strong>{{noticia[0].fecha}}</strong>
            </div>
        </div>
        <div class="bg-content p-4">
            <div class="text-center">
                <img class="mb-4 img-fluid text-center" :src="require('@/assets/noticias/' + noticia[0].portada)" alt="Notica">
            </div>
            <div class="row">
                <div v-for="(img, index) in noticia[0].imgup" :key="index" class="col">
                    <img class="mb-4 img-fluid text-center w-100" :src="require('@/assets/noticias/' + img.imagen)" alt="Notica">
                    <p class="text-center">{{img.texto}}</p>
                </div>
            </div>
            <p class="text-justify">{{noticia[0].text1}}</p>
            <div class="row">
                <div v-for="(img, index) in noticia[0].imgdow" :key="index" class="col">
                    <img class="mb-4 img-fluid text-center" :src="require('@/assets/noticias/' + img.imagen)" alt="Notica">
                    <p class="text-center">{{img.texto}}</p>
                </div>
            </div>
            <p class="text-justify">{{noticia[0].text2}}</p>
            <p class="text-justify">{{noticia[0].text3}}</p>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            noticias: [ 
                { 
                    slug: 'directivos', 
                    titulo: 'DIRECTIVOS DE AGEXPORT REALIZAN VISITA A PLANTA DE PRODUCCIÓN DE MOSCA DEL MEDITERRÁNEO ESTÉRIL, EL PINO DEL PROGRAMA MOSCAMED',
                    fecha: 'Barberena, Santa Rosa. Octubre 08, 2021',
                    portada: 'directivo-1.jpg', 
                    imgup: [],
                    text1: 'Se realiza visita a la Planta el Pino por parte de Directivos de Agexport del área agrícola y de café diferenciado, junto con representantes del MAGA, oficiales de USDA-APHIS; Jefatura Ejecutiva y Gerencia de Producción de Moscamed. Durante el recorrido se pudo revisar los procesos de operación de la planta de producción de mosca del Mediterráneo estéril.', 
                    imgdow: [
                        {imagen:'directivo-2.jpg', texto: ''},
                        {imagen:'directivo-3.jpg',  texto: ''}],
                    text2: '',
                    text3: '',
                },
                { 
                    slug: 'comision',   
                    titulo: 'REUNION MULTILATERAL DE COMISIONADOS DEL PROGRAMA MOSCAMED CON LA PARTICIPACION DE VICEMINISTRO DE MAGA, GUATEMALA Y AUTORIDADES DE SADER, MEXICO Y USDA, ESTADOS UNIDOS',
                    fecha: '23 de Noviembre 2021',
                    portada: 'portada-comision.jpg',
                    imgup: [
                        {imagen:'comision-1.jpg', texto: 'Delegación Moscamed Guatemala'},
                        {imagen:'comision-2.jpg', texto: 'Delegación MAGA Guatemala'},
                    ],
                    text1: 'De forma virtual se realizó la 7ma Reunión Multilateral de Comisionados del Programa MOSCAMED encabezando las delegaciones las siguientes autoridades: Ing. Víctor Hugo Guzmán Silva, Viceministro de Sanidad Agropecuaria y Regulaciones del Ministerio de Agricultura de Guatemala, MAGA; el Sr. Michael Watson, Administrador Asociado del Departamento de Agricultura de los Estados Unidos, USDA y el Dr. Francisco Javier Trujillo Arriaga, Director en Jefe del Servicio Nacional de Sanidad Inocuidad y Calidad Agroalimentaria de la Secretaria de Agricultura de México  SENASICA – SADER, máximas autoridades del Programa MOSCAMED que se reunieron con el propósito de verificar los avances obtenidos en la detección, control y erradicación de la mosca del Mediterráneo en la región, además se contó como invitado especial con el Sr. Servulo Baeza, Director Ejecutivo del Ministerio de Agricultura de Belice, MAF.',  
                    imgdow: [
                        {imagen:'comision-3.jpg', texto: 'Delegación MAGA – MOSCAMED, Guatemala'}],
                    text2: 'Entre los temas tratados durante la reunión anual del Programa MOSCAMED destaca la situación actual del Programa Moscamed Regional, así como la situación actual del Programa Moscamed en Belice, el Protocolo para el suministro de material biológico y/o asistencia técnica a entidades no trilaterales, la presentación del Plan Técnico y Financiero para el año 2022, la lectura de las Resoluciones del año 2021 y los Comentarios de cierre por parte de los Comisionados.',
                    text3: 'El Programa Moscamed forma parte de un convenio trilateral para la detección y control de la mosca del Mediterráneo entre Guatemala, México y Estados Unidos, en esta reunión también participó Belice, fortaleciendo la integración regional para la erradicación de esta plaga.',
                }
            ]
        };
    },
    computed: {
        slug() {
            let slug = this.$route.params.slug
           return   slug
        },
        noticia(){
            return this.noticias.filter((item) => {
                return item.slug.includes(this.$route.params.slug);
            });
        }
    }
}
</script>

<style scoped>
 .bg-title{
    background: #978759
 }

 .bg-content{
    background: #c1d4a0
 }
</style>
